<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card mb-0">
      <div class="card-header">
        <button class="btn" @click="back()" type="button">
          <i class="dropdown-icon si si-arrow-left"></i>
        </button>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12" v-for="(item, i) in messageList" :key="i">
            <div
              :class="'box ' + (item.e === 'her' ? 'left her' : 'right self')"
            >
              {{ item.message }}
              <div>
                {{ item.created_at }}
                <i class="fa-solid fa-check-double"></i>
                <i v-if="item.readed == 0" class="fa fa-check check"></i>
                <i v-if="item.readed == 1" class="fa fa-check-circle check"></i>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12">
            <div class="form-group">
              <label class="form-label">Mesaj</label>
              <textarea
                type="text"
                class="form-control"
                v-model="message"
                placeholder="Mesaj"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button
          type="button"
          @click="save"
          id="saveButton"
          class="btn btn-primary float-right"
        >
          Mesaj Gönder
        </button>
      </div>
    </div>
  </div>
</template>
<style scoped>
.box {
  position: relative;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  max-width: 50%;
  min-width: 40%;
  padding: 10px;
  margin-bottom: 10px;
}
.box > div {
  width: 100%;
  font-size: 10px;
}
.her {
  background: #ddd;
}
.self {
  background: rgb(211, 252, 201);
}
.check {
  margin-top: 3px;
  position: absolute;
  right: 8px;
  z-index: 5;
}
.self:before {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -15px;
  right: -23px;
  border: solid 15px transparent;
  border-right-color: rgb(211 252 201);
  z-index: 1;
  transform: rotateY(180deg);
}
.her:before {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -15px;
  left: -23px;
  border: solid 15px transparent;
  border-right-color: #ddd;
  z-index: 1;
}
.left {
  float: left;
}
.right {
  float: right;
}
</style>
    <script>
export default {
  created() {
    document.title = "Eğitim Başlığı";

    this.list();
  },
  data() {
    return {
      messageList: [],
      currentpage: 1,
      perpage: 8,
      totalsize: 0,
      pagesize: 0,
      message: "",
    };
  },
  methods: {
    back(){
      window.history.back(-1)
    },
    list() {
      let educationInfo = {
        begin: (this.currentpage - 1) * this.perpage,
        perpage: this.perpage,
        id: this.$route.params.id,
        member_id: this.$route.params.member_id,
      };
      this.$store
        .dispatch("educationMessageList", educationInfo)
        .then((value) => {
          this.messageList = value.list;
          this.totalsize = value.size;
          this.pagesize = value.pages;
        });
    },
    save() {
      if (this.message == null || this.message == "") {
        this.$vToastify.warning("Mesaj girilmedi", "Uyarı!");
      } else {
        document.getElementById("saveButton").disabled = true;
        document.getElementById("saveButton").innerHTML =
          "Bilgiler kayıt ediliyor..";
        let educationInfo = {
          id: this.$route.params.id,
          message: this.message,
          member_id: this.$route.params.member_id,
        };
        this.$store
          .dispatch("educationMessageSend", educationInfo)
          .then((value) => {
            this.list();
            this.message = "";
            document.getElementById("saveButton").disabled = false;
            document.getElementById("saveButton").innerHTML = "Mesaj Gönder";
          });
      }
    },
  },
  components: {},
  mounted() {
    $(document).ready(function () {
      $("#content").summernote({
        toolbar: [
          [
            "style",
            ["highlight", "bold", "italic", "underline", "clear", "style"],
          ],
          ["font", ["strikethrough", "superscript", "subscript", "fontname"]],
          ["color", ["color"]],
          ["table", ["table"]],
          ["para", ["ul", "ol", "paragraph", "hr"]],
          ["insert", ["tableofcontent"]],
          ["link", ["linkDialogShow", "unlink"]],
          ["view", ["fullscreen", "codeview", "help"]],
        ],
      });
    });
  },
};
</script>